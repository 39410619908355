<!--促销计划-->
<template>
    <div class="Plan">
        <el-card shadow="never" style="margin-top: 8px">
            <el-page-header @back="goBack" />
        </el-card>
        <el-card shadow="never" style="margin-top: 8px">
            <el-form
                @submit.native.prevent
                :inline="true"
                style="text-align: right"
                v-model="form"
                size="small"
                label-width="85px"
            >
                <el-row style="margin-top: 16px">
                    <el-col :span="8">
                        <el-form-item label="机构组名称">
                            <el-input placeholder="关键字" v-model="form.deptGroupName" readonly />
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="计划名称">
                            <el-input v-model="form.name" readonly />
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="查询条件">
                            <el-input v-model="deptData.search" />
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row style="margin-top: 16px">
                    <el-col :span="8">
                        <el-form-item label="启用状态">
                            <el-select placeholder="请选择" v-model="deptData.status">
                                <el-option label="请选择" value="" />
                                <el-option label="启用" :value="1" />
                                <el-option label="停用" :value="2" />
                            </el-select>
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-form>
        </el-card>
        <el-card shadow="never" style="margin-top: 8px">
            <el-button type="primary" @click="handleQuery" size="small">查询</el-button>
            <el-button type="primary" @click="patchEnable" size="small" :disabled="disablePatchEnableBtn"
                >批量启用</el-button
            >
            <el-button type="primary" @click="patchDisable" size="small" :disabled="disablePatchDisableBtn"
                >批量停用</el-button
            >
        </el-card>
        <el-card shadow="never" style="margin-top: 8px">
            <el-table
                border
                ref="ProgrammeShow"
                stripe
                style="width: 100%"
                :data="tableData"
                size="mini"
                :highlight-current-row="true"
                max-height="460"
                @selection-change="handleSelectionChange"
            >
                <el-table-column label="全选" type="selection" width="60" align="center" fixed="left" />
                <el-table-column label="序号" width="60" type="index" fixed="left" />
                <el-table-column label="机构名称" width="160" prop="name" />
                <el-table-column label="省" width="120" prop="provinceName" />
                <el-table-column label="市" width="120" prop="cityName" />
                <el-table-column label="区" width="120" prop="districtName" />
                <el-table-column label="地址" width="120" prop="address" />
                <el-table-column label="启用状态" width="120" prop="flag">
                    <template slot-scope="scope"
                        ><span>{{ scope.row.flag == true ? '启用' : '停用' }}</span></template
                    >
                </el-table-column>
                <el-table-column label="操作" min-width="360" header-align="center">
                    <template slot-scope="scope">
                        <el-button size="mini" type="primary" @click="enableFlag(scope.row)" :disabled="scope.row.flag"
                            >启用</el-button
                        >
                        <el-button size="mini" type="danger" @click="disableFlag(scope.row)" :disabled="!scope.row.flag"
                            >停用</el-button
                        >
                    </template>
                </el-table-column>
            </el-table>
            <ef-pagination @pageChange="pageChange" :total="page.total" :default-limit="form.limit" />
        </el-card>
    </div>
</template>

<script>
import Util from 'js/Util';
import UrlUtils from 'js/UrlUtils';
import EfPagination from 'components/EfPagination';
import { del } from 'request/http';

export default {
    name: 'GoodsPlanDeptDetail',
    props: ['form'],
    components: { EfPagination },
    data() {
        return {
            deptData: {
                bizCode: this.form.code,
                deptGroupCode: this.form.deptGroupCode,
                status: '',
                search: '',
                page: 1,
                limit: 100,
            },
            page: {
                total: 0,
            },
            selectedBizArr: [],
            tableData: [],
            url: {
                page: '/merchandising/findDept',
                handleOn: '/merchandisingAndDept/binding',
                handleOff: '/merchandisingAndDept/deleteBatchCodes',
            },
            current: {
                typeIdx: 1,
            },
            meta: {
                reviewEnums: Util.reviewStatusEnum(true),
            },
        };
    },
    mounted() {
        this.handleQuery();
    },
    computed: {
        //禁用/启用按钮
        disablePatchEnableBtn() {
            if (this.selectedBizArr.length === 0) {
                return true;
            }
            const disableBtnFlag = this.selectedBizArr.findIndex((e) => {
                return e.flag;
            });
            return disableBtnFlag >= 0;
        },
        //禁用/启用按钮
        disablePatchDisableBtn() {
            if (this.selectedBizArr.length === 0) {
                return true;
            }
            const disableBtnFlag = this.selectedBizArr.findIndex((e) => {
                return !e.flag;
            });
            return disableBtnFlag >= 0;
        },
    },
    methods: {
        patchEnable() {
            this.handleEnableFlag(true, this.selectedBizArr);
        },
        patchDisable() {
            this.handleEnableFlag(false, this.selectedBizArr);
        },
        enableFlag(detail) {
            this.handleEnableFlag(true, [detail]);
        },
        disableFlag(detail) {
            this.handleEnableFlag(false, [detail]);
        },
        handleEnableFlag(flag, selected) {
            selected = selected || [];
            if (selected.length <= 0 || Util.isEmpty(flag)) {
                this.$message.error('未选择数据无法设置启用停用');
                return;
            }
            if (flag) {
                //启用
                const params = { deptCodes: selected.map((e) => e.deptCode), merchandisingCode: this.form.code };
                UrlUtils.PostGoodsplan(this, this.url.handleOn, params);
            } else {
                //停用
                del(this.url.handleOff, { codes: selected.map((e) => e.code) }).then(() => {
                    this.handleQuery();
                });
            }
        },
        handleQuery() {
            const params = { params: this.deptData };
            Util.queryTable(this, this.url.page, params);
        },
        pageChange(page, limit) {
            this.form.page = page;
            this.form.limit = limit;
            this.handleQuery();
        },
        handleSelectionChange(selections) {
            this.selectedBizArr = selections;
        },
    },
};
</script>

<style scoped>
.Plan .el-form-item {
    margin-bottom: 0;
}
</style>
